@import './variable';

.body-landing-page {
  background-color: $background-landing-cl;
}

.text-h1-home {
  font-size: 5rem;
  line-height: 5rem;
  font-weight: 600;
  font-family: $font-heading !important;
  color: $color-blue-text;
  span {
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: $color-blue-text;
  }
}

.text-center-empty {
  font-family: $font-heading !important;
}

.info-website {
  min-height: 750px;
}

.text-des-home {
  color: $color-blue-text;
}

.right-img {
  right: -100px;
}

.logo-banner {
  left: 70px;
}

.img-radius {
  img {
    height: 750px;
  }
}

.introduce {
  p {
    font-size: 25px;
    line-height: 2;
  }
}
