@import './variable';

.container-app {
  max-width: 980px;
}

.logo {
  font-family: 'Sabreen', serif;
}

.text-color-landing-page {
  color: $color-blue-text;
}

.body-main-layout {
  background-color: $color-main-cl;
}

.customUpload {
  min-height: 130px !important;
  border: none !important;
}
