@font-face {
  font-family: 'Sabreen';
  src: url('../fonts/Sabreen-RegularDemo.woff2') format('woff2'),
    url('../fonts/Sabreen-RegularDemo.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Heading Now Trial 88';
  src: url('../fonts/HeadingNowTrial-88Heavy.woff2') format('woff2'),
    url('../fonts/HeadingNowTrial-88Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('../fonts/SofiaProLight.woff2') format('woff2'),
    url('../fonts/SofiaProLight.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
