.button-custom {
  &-left {
    left: -90px;
  }
  &-right {
    right: -90px;
    display: block;
    transition: transform 200ms ease 0s;
    transform: rotate(180deg);
  }
  top: calc(50% - 25px);
  position: absolute;
  bottom: 0px;
  height: 50px;
  width: 50px;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  display: flex !important;
  font-size: 0px;
  border-radius: 50%;
  background: transparent;
  transform: unset;

  flex-direction: row;
  margin: 0px;
  line-height: 1rem;
  font-weight: 500;
  text-decoration: none;
  justify-content: center;
  text-align: center;
  padding: 12px 16px;
  border: 2px solid rgb(234, 234, 234);
  color: rgb(38, 38, 38);
  transition: transform 100ms ease 0s, opacity 150ms ease 0s, border-color 150ms ease 0s,
    background-color 150ms ease 0s, color 150ms ease 0s, box-shadow 150ms ease 0s,
    font-weight 150ms ease 0s;
  &:hover {
    border: 2px solid rgb(190, 190, 190);
    color: rgb(38, 38, 38);
    opacity: 0.75;
  }
}
